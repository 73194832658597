import { template as template_9001382c461b4d2e8f2b230e9eee9e16 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_9001382c461b4d2e8f2b230e9eee9e16(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
