import { template as template_a5d6a7be77a541f4bef8e1d7bec498d3 } from "@ember/template-compiler";
const FKText = template_a5d6a7be77a541f4bef8e1d7bec498d3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
