import { template as template_78e735edb21244539b3a4b03ae6b3950 } from "@ember/template-compiler";
const FKLabel = template_78e735edb21244539b3a4b03ae6b3950(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
